exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nosupport-tsx": () => import("./../../../src/pages/nosupport.tsx" /* webpackChunkName: "component---src-pages-nosupport-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-undercontruction-tsx": () => import("./../../../src/pages/undercontruction.tsx" /* webpackChunkName: "component---src-pages-undercontruction-tsx" */)
}

